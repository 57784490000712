import React, { Fragment } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import Selectors from "../Selectors";
import * as Cart from "../Contexts/CartContext";
import { getDefaultConfigWithExtra } from "../Components/ProductParam";
import ProductItem from "../Components/ProductItem";
import * as Ant from "antd";
import ProductImageCarousel from "../Components/ProductImageCarousel";
import ProductImageLightBox from "../Components/ProductImageLightBox";
import ProductBottomTabs from "../Components/ProductBottomTabs";
import ActionCreator from "../ActionCreator";
import * as Theme from "../Theme";
import Configs from "../Components/ProductConfigsInDetail";
import useDimension from "../hooks/use-dimension";
import { Context } from "../AppContext";
import { navigate } from "gatsby";
import Breadcrumb from "../Components/Breadcrumb";
import Tag from "../Components/ProductTag";

import GA from "../Utils/GA";
import Pixel from "../Utils/fbPixel";
import { errorHandler } from "../errors";
import { Helmet } from "react-helmet";

const slugify = require("uslug");

function getProductImages(product) {
  let images = [];
  if (product.image) {
    images.push({ image: product.image });
  }

  return images
    .concat(product.images)
    .map(i => ({ image: i.image, name: i.name }));
}

function ProductDetailPage(props) {
  const { dimension, rwd } = useDimension();
  const { product } = props.pageContext;
  const app = React.useContext(Context);

  const [productMeta, setProductMeta] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [values, setValues] = React.useState(
    getDefaultConfigWithExtra({
      product: props.pageContext.product,
    })[0]
  );
  const [extra, setExtra] = React.useState(
    getDefaultConfigWithExtra({
      product: props.pageContext.product,
    })[1]
  );

  const [price, setPrice] = React.useState(null);
  const [requireDay, setRequireDay] = React.useState(null);
  const [loadingPrice, setLoadingPrice] = React.useState(false);
  const [loadingCart, setLoadingCart] = React.useState(false);
  const [originalAmount, setOriginalAmount] = React.useState(null);
  const [relatedProduct, setRelatedProduct] = React.useState(null);

  const pad = dimension.innerWidth <= Theme.breakpoints.lg;
  const outOfStock = !!productMeta?.is_out_stock;
  const _getRelatedProduct = async relatedId => {
    try {
      if (relatedId) {
        let relatedProduct = await app.actions.getProduct({
          id: relatedId,
        });
        setRelatedProduct(relatedProduct);
      }
    } catch (ex) {
      console.warn(ex);
    }
  };

  const _onConfigChange = async (config, new_extra = {}) => {
    setLoadingPrice(true);
    try {
      const { product } = props.pageContext;

      const itemConfig = {
        name: product.name,
        config,
      };

      let resp = await app.actions.calcProduct(itemConfig);
      setValues(config);
      setPrice(resp.price);
      setRequireDay(resp.require_day);
      setExtra({ ...extra, new_extra });

      // calc original amount
      if (resp.quantity && extra.original_price) {
        setOriginalAmount(resp.quantity * extra.original_price);
      } else {
        setOriginalAmount(null);
      }
    } catch (ex) {
      console.warn(ex);
    }
    setLoadingPrice(false);
  };

  const _addToCart = async (callback, price) => {
    if (!props.profile) {
      app.actions.setLoginModal(true);
      return;
    }

    if (JSON.stringify(app.state.specsError) !== "{}") {
      Ant.message.error("規格錯誤，請重新選擇");
      setLoadingCart(false);
      return;
    }

    setLoadingCart(true);

    try {
      const itemInCart = {
        product: productMeta.id,
        config: values,
        price, // this price is for facebook conversion api
      };

      await Cart.Actions.addItem(itemInCart, app.state.spec?.version);
      callback();
    } catch (ex) {
      errorHandler(ex, "加入購物車發生錯誤。");
    }
    setLoadingCart(false);
  };

  React.useEffect(() => {
    async function getProductData() {
      app.actions.setLoading(true);
      const { product } = props.pageContext;
      try {
        const resp = await app.actions.getProduct({ name: product.name });
        _getRelatedProduct(resp.results[0].related_product);
        setProductMeta(resp.results[0]); // we need to switch this earlier, since we still need to call calc price api
        // calculate the default price
        await _onConfigChange(values, extra);
      } catch (ex) {
        console.warn(ex);
      }
      app.actions.setLoading(false);
      Pixel.viewContent([product.name]);
      GA.addImpression({
        id: product.name,
        name: product.name,
      });
    }
    getProductData();
  }, []);

  const relatedProductSpec = React.useMemo(() => {
    if (relatedProduct && app.actions.getProductFromSpec) {
      return app.actions.getProductFromSpec({
        productName: relatedProduct.name,
      });
    }
    return null;
  }, [relatedProduct, app.actions.getProductFromSpec]);

  const [period, totalOfPeriod] = React.useMemo(() => {
    if (product.is_period) {
      let option = product.period_select.options.find(
        o => o.name === values.period_select
      );
      if (option) {
        return [option.period, price * option.period];
      }
    }
    return [];
  }, [values, product, price]);

  return (
    <Fragment>
      <Helmet>
        <meta name="description" content={productMeta?.description} />

        <script type="application/ld+json">{`
        {
            "@context": "http://schema.org",
            "@type": "Product",
            "name": "${productMeta?.name}",
            "image": [${productMeta?.images.map(image => `"${image.image}"`)}],
            "description": "${productMeta?.description}",
            "sku": "${product.id}",
            "brand": {
                "@type": "Brand",
                "name": "感官印制"
            },
            "offers": {
                "@type": "Offer",
                "price": "${productMeta?.price}",
                "priceCurrency": "TWD",
                "priceValidUntil": "2025-04-10",
                "itemCondition": "https://schema.org/NewCondition",
                "availability": "https://schema.org/InStock"
            },
            "additionalProperty": [
              {
                "@type": "PropertyValue",
                "name": "Hashtag",
                "value": "${productMeta?.hashtag}"
              },
              {
                "@type": "PropertyValue",
                "name": "Tag",
                "value": "${productMeta?.tag}"
              }
            ]
          }
    `}</script>
      </Helmet>
      <Wrapper isMobile={pad}>
        {!app.state.loading && !productMeta && (
          <Ant.Alert message="此商品不存在" />
        )}
        {productMeta && (
          <div className="breadcrumb">
            <div className="center-content">
              <Breadcrumb
                routes={[
                  {
                    label: "線上訂購",
                    link: `/products`,
                  },
                  {
                    label: productMeta.label,
                    link: `/products?category=${productMeta.label}`,
                  },
                  { label: productMeta.name, link: "" },
                ]}
                seperator="/"
                style={{}}
                color="#707070"
              />
            </div>
          </div>
        )}
        <div className="center-content">
          <Ant.Row
            style={{
              margin: (() => {
                if (rwd === "desktop") {
                  return "45px 0px";
                } else if (rwd === "pad") {
                  return "40px 0px";
                } else {
                  return "24px 0px";
                }
              })(),
            }}
          >
            <Ant.Col md={24} lg={14}>
              <div className="title">
                <TitleText>
                  {product.name}
                  <Tag
                    product={productMeta}
                    freeShipping={product.free_shipping}
                    isPeriod={product.is_period}
                    onItem={false}
                  />
                </TitleText>
              </div>
            </Ant.Col>
          </Ant.Row>

          <Ant.Row gutter={{ md: 0, lg: 54 }}>
            <Ant.Col md={24} lg={14} className="product-display">
              <div className="images">
                {productMeta && (
                  <>
                    <ProductImageCarousel
                      imagesArray={getProductImages(productMeta)}
                    />
                    <div style={{ height: 10 }} />
                    {/* <ProductImageLightBox
                    imagesArray={getProductImages(productMeta)}
                  /> */}
                  </>
                )}
              </div>
              <div className="description">
                {productMeta && (
                  <p
                    style={{
                      color: "#9b9b9b",
                      paddingTop: 15,
                      fontSize: 14,
                    }}
                  >
                    {productMeta.description}
                  </p>
                )}
              </div>
            </Ant.Col>
            <Ant.Col md={24} lg={10} className="product-behavior">
              <div className="configs">
                <Configs
                  product={product}
                  values={values}
                  extra={extra}
                  requireDay={requireDay}
                  loadingPrice={loadingPrice}
                  onConfigChange={_onConfigChange}
                  productMeta={productMeta}
                />
              </div>
              <div className="price">
                <PriceRow style={{ flex: 2 }}>
                  <label>優惠價格</label>
                  <span style={{ fontSize: 30, whiteSpace: "pre-wrap" }}>
                    {`\$${(!loadingPrice && price) || "---"} `}{" "}
                  </span>
                  <div>元 / 含稅</div>
                </PriceRow>
                {/* <PriceRow style={{ flex: 3 }}>
                {!pad && (
                  <div style={{ margin: "0 5px 0 10px", color: "#a0a0a0" }}>
                    {"/"}
                  </div>
                )}
                <label
                  style={{
                    margin: pad ? "0" : "0 10px 0 5px",
                    flexBasis: pad ? "100px" : "auto",
                    color: "#a0a0a0",
                  }}
                >
                  {"原價"}
                </label>
                <div
                  style={{ textDecoration: "line-through", color: "#a0a0a0" }}
                >
                  {`\$${(!loadingPrice && originalAmount) || "---"} 元`}
                </div>
              </PriceRow> */}
              </div>

              {/* 訂閱制金額試算 */}
              {product.is_period && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "baseline",
                    marginBottom: 10,
                  }}
                >
                  {" "}
                  {"$ "}
                  {totalOfPeriod} 元 / {period} 期 = {"每期優惠金額 $ "}
                  {price} 元{" "}
                </div>
              )}

              <div className="actions">
                <BuyItNowBtn
                  type="primary"
                  loading={loadingCart}
                  disabled={loadingCart || loadingPrice || outOfStock}
                  onClick={async () => {
                    await _addToCart(() => navigate("/cart/"), price);
                  }}
                  block
                >
                  {outOfStock ? "商品已售完 Sold Out!" : "Buy It Now!"}
                </BuyItNowBtn>

                <AddToCartBtn
                  type="primary"
                  loading={loadingCart}
                  disabled={loadingCart || loadingPrice || outOfStock}
                  onClick={async () => {
                    await _addToCart(() => {}, price);
                  }}
                  block
                >
                  Add to Cart
                </AddToCartBtn>
              </div>
              {loadingPrice && <LoadingPlaceholder />}
              {relatedProduct && (
                <div className="ref-product">
                  <label
                    style={{
                      color: Theme.colors.text,
                      marginBottom: 15,
                    }}
                  >
                    推薦商品
                  </label>
                  <ProductItem
                    horizontalMode
                    product={relatedProduct}
                    spec={relatedProductSpec}
                    onClick={() => {
                      app.actions.setLoading(true);
                    }}
                    link={`/product/${relatedProduct.id}`}
                    extraCss="height: calc(2 * 15px + 176px);padding: 15px; margin-bottom: 0; border:solid 1px #eee; "
                  />
                </div>
              )}
            </Ant.Col>
          </Ant.Row>
          <div className="product-tab-panel">
            {productMeta && <ProductBottomTabs product={productMeta} />}
          </div>
          <div className="hashtags"></div>
        </div>
      </Wrapper>
    </Fragment>
  );
}

const Wrapper = styled.div`
  & > .center-content {
    max-width: ${Theme.centerContentMaxWidth};
    margin: 0px auto;
    & .title {
      padding: ${props =>
        props.isMobile ? `0px ${Theme.rwdPadding.outer}` : "0px"};
    }
    & > .product-tab-panel {
      padding: ${props => (props.isMobile ? Theme.rwdPadding.outer : 0)};
      margin-top: 54px;
    }
    & > .hashtags {
    }
  }

  & > .breadcrumb {
    background-color: ${Theme.colors.sub};
    height: ${props => (props.isMobile ? "45px" : "60px")};
    display: flex;
    & > .center-content {
      display: flex;
      flex-direction: row;
      flex: 1;
      align-items: center;
      margin: 0 auto;
      max-width: ${Theme.centerContentMaxWidth};
      padding: ${props =>
        props.isMobile ? `0px ${Theme.rwdPadding.outer}` : "0px"};
    }
  }

  & .product-display {
    padding: ${props => (props.isMobile ? Theme.rwdPadding.outer : 0)};
    & > .description {
      padding: ${props => (props.isMobile ? Theme.rwdPadding.inner : 0)};
      ${Theme.textContentStyle}
    }
    & > .images {
      margin-bottom: 15px;
    }
  }

  & .product-behavior {
    padding: ${props => (props.isMobile ? Theme.rwdPadding.outer : 0)};
    & > .configs {
      padding: 0 ${props => (props.isMobile ? Theme.rwdPadding.inner : 0)}px;
    }
    & > .price {
      padding: 0 ${props => (props.isMobile ? Theme.rwdPadding.inner : 0)}px;
      display: flex;
      flex-direction: ${props => (props.isMobile ? "column" : "row")};
      align-items: ${props => (props.isMobile ? "stretch" : "baseline")};
    }
    & > .actions {
    }
    & > .ref-product {
      margin-top: 30px;
    }
  }
`;

const TitleText = styled.div`
  ${Theme.textTitleStyle}
`;

const LoadingPlaceholder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PriceRow = styled.div`
  display: flex;
  align-items: baseline;
  color: ${Theme.colors.brown};
  font-weight: 600;
  margin-bottom: 15px;

  & > label {
    flex-basis: 100px;
  }
`;

const BuyItNowBtn = styled(Ant.Button)`
  margin-bottom: 17px;
  background-color: ${Theme.colors.main};
  border-color: ${Theme.colors.sub};
  padding: 15px;
  font-size: 16px;
  height: auto;
  :hover {
    background-color: ${Theme.colors.text};
  }
`;

const AddToCartBtn = styled(Ant.Button)`
  background-color: ${Theme.colors.lightGreen};
  border-color: ${Theme.colors.lightGreen};
  color: ${Theme.colors.main};
  padding: 15px;
  font-size: 16px;
  height: auto;
  :hover {
    color: ${Theme.colors.main};
    background-color: #9eb5b7;
    border-color: #9eb5b7;
  }
`;

export default connect(
  (state, ownProps) => ({
    profile: Selectors.getLoginUser(state),
  }),
  ActionCreator
)(ProductDetailPage);
